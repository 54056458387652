<template>
  <v-card
    class="d-flex align-center justify-center rounded-0 bg pa-3"
    width="100vw"
    min-height="100vh"
  >
    <v-stepper
      v-model="e1"
      width="400px"
      height="100vh"
      max-height="600px"
      class="rounded-xl pa-5 d-flex align-center justify-center"
      style="background-color: rgb(255, 255, 255)"
      dark
    >
      <v-stepper-items class="main-item">
        <v-stepper-content step="1">
          <p style="color: black">Please enter new password:</p>
          <v-text-field
            :rules="[rules.required, rules.min]"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            name="input-10-2"
            :label="this.$t('Old Password')"
            :hint="this.$t('hintPasswordField')"
            value=""
            color="black"
            v-model="userPassword"
            class="input-group--focused"
            @click:append="show3 = !show3"
            light
          />
          <v-text-field
            :rules="[rules.required, rules.min]"
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            name="input-10-2"
            :label="this.$t('New Password')"
            :hint="this.$t('hintPasswordField')"
            value=""
            color="black"
            v-model="newuserPassword"
            class="input-group--focused"
            @click:append="show3 = !show3"
            v-show="!isLogin"
            light
          />
          <v-btn color="black" @click="e1 = 2"> {{ $t("continue") }} </v-btn>
          <v-btn text @click="GotoLogin" color="black">{{
            $t("cancel")
          }}</v-btn>
        </v-stepper-content>
        <v-stepper-content step="2">
          <p style="color: black">Are you sure to change your password?</p>
          <g-recaptcha
            data-sitekey="6LcEqtQlAAAAAGHpVRo-Yb6sVo0o_3fa9jT2qhoP"
            class="input-group--focused width-100 d-flex justify-center"
            :data-validate="validate"
            :data-callback="callback"
          >
            <v-btn
              elevation="2"
              min-width="150px"
              :loading="loading"
              :disabled="loading"
              @click="loader = 'loading'"
              class="mb-3 width-100"
              >{{ $t("Yes and Log in again") }}</v-btn
            >
          </g-recaptcha>
          <v-btn style="width: 100%" text @click="e1 = 1" color="black">{{
            $t("cancel")
          }}</v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import gRecaptcha from "@finpo/vue2-recaptcha-invisible";
import { callApiChangePassword } from "../../../store/Method/index";

export default {
  components: {
    gRecaptcha,
  },
  data() {
    return {
      e1: 1,
      show3: false,
      userPassword: "",
      newuserPassword: "",
    };
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t("required"),
        min: (v) => v.length >= 1 || this.$t("hintPasswordField"),
        emailMatch: () => this.$t("emailMatch"),
        passwordMatch: (v) =>
          this.userPassword === v || this.$t("passwordMatch"),
      };
    },
  },
  methods: {
    GotoLogin() {
      this.$router.push("/Login");
    },
    validate() {
      return true;
    },
    async callback(token) {
      if (token) {
        await callApiChangePassword(
          this.userPassword,
          this.newuserPassword,
          localStorage.getItem("MTNToken")
        ).then(async () => {
          await this.$store.dispatch("User/logout");
          this.$router.push("/login");
        });
      } else {
        alert("Please check you are not robot!!!");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bg {
  background-color: #006780;
  background-size: cover;
}
.hidden {
  display: none !important;
}
.main-item {
  width: 100%;
}
</style>
